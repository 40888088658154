<template>
  <div class="scanQRcode">
    
    <el-select v-model="select" class="select">
      <el-option v-for="(value,i) in selectArray" :key="i" :label="value.devNickname" :value="value.jourDeviceId"></el-option>
    </el-select>
    <!-- <el-input v-model="value" readonly class="numberinput" type="password" /> -->
    <van-field v-model="value" readonly class="numberinput" type="password" />
    <van-grid :border="false" :column-num="3">
      <van-grid-item>
        <el-button @click="clickNumber('7')" circle>7</el-button>
      </van-grid-item>
      <van-grid-item>
        <el-button @click="clickNumber('8')" circle>8</el-button>
      </van-grid-item>
      <van-grid-item>
        <el-button @click="clickNumber('9')" circle>9</el-button>
      </van-grid-item>

      <van-grid-item>
        <el-button @click="clickNumber('4')" circle>4</el-button>
      </van-grid-item>
      <van-grid-item>
        <el-button @click="clickNumber('5')" circle>5</el-button>
      </van-grid-item>
      <van-grid-item>
        <el-button @click="clickNumber('6')" circle>6</el-button>
      </van-grid-item>

      <van-grid-item>
        <el-button @click="clickNumber('1')" circle>1</el-button>
      </van-grid-item>
      <van-grid-item>
        <el-button @click="clickNumber('2')" circle>2</el-button>
      </van-grid-item>
      <van-grid-item>
        <el-button @click="clickNumber('3')" circle>3</el-button>
      </van-grid-item>

      <van-grid-item>
        <el-button @click="clean" circle icon="el-icon-close"></el-button>
      </van-grid-item>
      <van-grid-item>
        <el-button @click="clickNumber('0')" circle>0</el-button>
      </van-grid-item>
      <van-grid-item>
        <el-button @click="enter" circle icon="el-icon-check"></el-button>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
export default {
  name: 'scanQRcode',
  data () {
    return {
      select: '',
      value: '',
      selectArray: [],
      dataArray: [],
    }
  },
  created () {
    this.selectMenu()
  },
  methods: {
    selectMenu() {
      this.$toast.loading({
        message: '拼命加载中...',
        duration: 0, // 持续展示 toast
        forbidClick: true,
      })
      // let str = 'http://www.fangzhizun.com/scanQRcode?c=jd&h=220'
      // let str = 'http://fzz1.cn/a?c=jd&h=220'
      let str = window.location.href
      this.dataArray = str.split('?')[1].split('&')
      this.dataArray = this.dataArray.map(item => {
        item = item.split('=')[1]
        return item
      })
      this.$axios({
        method: 'get',
        url: '/common2/devices/getLockDeviceList',
        params: {
          co: this.dataArray[0],
          hsId: Number(this.dataArray[1]),
        }
      }).then(res => {
        if (res.data.result.length > 0) {
          this.selectArray = res.data.result
          this.select = this.selectArray[0].jourDeviceId
        } else {
          this.selectArray = []
        }
        this.$toast.clear();
      }).catch(err => {
        this.$toast.clear();
        this.$toast.fail(`${err.response.data.msg}`)
      })
    },
    clickNumber(val) {
      this.value += val
    },
    clean() {
      this.value = ''
    },
    enter() {
      this.$toast.loading({
        message: '拼命加载中...',
        duration: 0, // 持续展示 toast
        forbidClick: true,
      })
      this.$axios({
        method: 'post',
        url: '/common2/open/doorCard/unlock',
        params: {
          co: this.dataArray[0],
          jdcHsId: Number(this.dataArray[1]),
          jourDeviceId: this.select,
          jdcPassword: this.value,
        }
      }).then(res => {
        this.value = ''
        this.$toast.clear();
        this.$toast.success(`开锁成功`)
      }).catch(err => {
        this.value = ''
        this.$toast.clear();
        this.$toast.fail(`${err.response.data.msg}`)
      })
    },
  }

}
</script>

<style lang="scss">
  .scanQRcode{
    .van-grid-item__content{
      background-color: transparent;
    }
  }
</style>
<style lang="scss" scoped>
  .scanQRcode{
    width: 100vw;
    height: 100vh;
    background: url('../../assets/unlock-background.png') no-repeat center center;
    background-size: 100vw 100vh;
    text-align: center;
    overflow: hidden;
    .select{
      margin: 10vh auto 4vh;
      font-size: 4vh;
    }
    .numberinput{
      width: 80vw;
      margin: 0 auto 2vh;
      font-size: 4vh;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
    }
  }
  .el-button.is-circle{
    font-size: 5vh;
    font-weight: 300;
    width: 10vh;
  }
  .el-button:hover, .el-button:focus{
    background: #FFFFFF;
    border-color: #DCDFE6;
    color: #606266;
  }
</style>
